import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import BottomNav from '../../containers/Page/Survey/BottomNav'
import { EntryType } from '../../data/model/questionnaire'
import { useUpdateFieldworkV2 } from '../../hooks/audience/createOrUpdateFieldworkV2'
import useGetDraftQuestionnaire from '../../hooks/questionnaire/useGetDraftQuestionnaire'
import useQuestionnaireValidation from '../../hooks/questionnaire/useQuestionnaireValidation'
import useCalculateLengthOfInterview from '../../hooks/useCalculateLengthOfInterview'
import { useFieldwork } from '../../hooks/useFieldwork'
import { useGetFeasibilityEstimateData } from '../../hooks/useGetFeasibilityEstimateData'
import { useProjectId } from '../../hooks/useProjectId'
import { useSurveyId } from '../../hooks/useSurveyId'
import {
  checkIfMasterSurveyPage,
  handleShowPreview
} from '../../utils/HelperFunctions'
import { convertAudienceType } from '../../utils/audienceHelper'
import {
  checkIsQuestionnaireValid,
  newEntryId
} from '../../utils/questionnaireUtils'
import { AddQuestionMenu } from './AddQuestionMenu'

export const BottomNavContainer = () => {
  const { flattenedEntries, draftQuestionnaire } = useGetDraftQuestionnaire()

  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const navigate = useNavigate()
  const { isFeasible, isAudienceValid } = useGetFeasibilityEstimateData()
  const { hasValidationErrors } = useQuestionnaireValidation()
  const calculatedLengthOfInterview = useCalculateLengthOfInterview()
  const isMasterSurveyPage = checkIfMasterSurveyPage()
  const { fieldwork } = useFieldwork()

  const sectionEntries =
    draftQuestionnaire?.entries.filter(
      (e) => e.entryType === EntryType.SectionEntryType
    ) ?? []

  const lastEntryPosition = Math.max(
    -1,
    ...[...flattenedEntries, ...sectionEntries].map((e) => e.position)
  )

  const isQuestionnaireEmpty = flattenedEntries.length === 0

  const panelSupplierCode = convertAudienceType(
    fieldwork?.audience[0].__typename
  )

  const routeToConfirmation: () => void = useCallback(() => {
    const isQuestionnaireValid = checkIsQuestionnaireValid(draftQuestionnaire)

    if (isQuestionnaireValid) {
      navigate(`/project/${projectId}/survey/${surveyId}/confirm`)
    }
  }, [draftQuestionnaire, navigate, surveyId, projectId])

  const { updateFieldworkV2 } = useUpdateFieldworkV2()

  const handleClickToConfirmation = useCallback(() => {
    newEntryId(undefined)
    updateFieldworkV2({
      variables: {
        input: {
          surveyId,
          panelSupplierCode: panelSupplierCode,
          lengthOfInterview: calculatedLengthOfInterview,
          samplesNeeded: null,
          startDate: null,
          endDate: null,
          countryCode: null
        }
      },
      onCompleted: routeToConfirmation
    })
  }, [
    calculatedLengthOfInterview,
    panelSupplierCode,
    routeToConfirmation,
    surveyId,
    updateFieldworkV2
  ])

  const rightButtonDisabled =
    !isFeasible ||
    isQuestionnaireEmpty ||
    !isAudienceValid ||
    hasValidationErrors()

  const middleButtonControl = useMemo(
    () =>
      draftQuestionnaire?.entries.length ? (
        <AddQuestionMenu
          showAddButton
          showSectionMenu
          position={lastEntryPosition + 1}
        />
      ) : null,
    [draftQuestionnaire?.entries.length, lastEntryPosition]
  )

  const handleClickSecondaryRightButton = useCallback(
    () => handleShowPreview(surveyId, panelSupplierCode),
    [panelSupplierCode, surveyId]
  )

  return (
    <BottomNav
      width={'calc(100% - 56px)'}
      overrideDisableButtons={isQuestionnaireEmpty}
      middleButtonText="Add question"
      middleButtonControl={middleButtonControl}
      rightButtonText="To confirmation"
      rightButtonDisabled={rightButtonDisabled}
      onClickRightButton={
        isMasterSurveyPage ? undefined : handleClickToConfirmation
      }
      secondaryRightButtonDisabled={isQuestionnaireEmpty}
      secondaryRightButtonText="Preview"
      onClickSecondaryRightButton={
        isMasterSurveyPage ? undefined : handleClickSecondaryRightButton
      }
    />
  )
}
