import {
  Grid,
  InfoIcon,
  InfoIconColor,
  Input,
  InputFontSize,
  InputType,
  InputVariant,
  InputWeight,
  Switch,
  Text,
  TextSize,
  TextWeight,
  TipPlacement,
  Tooltip,
  TooltipPlacement
} from '@focaldata/cin-ui-components'
import { useStyles } from '.'

interface Props {
  isToggledOn: boolean
  inputPlaceholder?: number
  softLaunchSamplesNeeded?: number
  onToggle: (event: React.ChangeEvent<HTMLInputElement>) => void
  onInputBlur?: (value: string) => void
  hasInputError?: boolean
  inputErrorMessage?: string
  infoText?: string
  isConfiguredForIncrementalFieldwork?: boolean
  disabledSoftLaunchMessage?: string
  isPanelCint: boolean
}

const ConfirmationSoftLaunchControl: React.FC<Props> = (props: Props) => {
  const {
    isToggledOn,
    inputPlaceholder,
    softLaunchSamplesNeeded,
    onToggle,
    onInputBlur,
    hasInputError,
    inputErrorMessage,
    isConfiguredForIncrementalFieldwork = false,
    infoText = 'Launch your project initially with a fraction of the sample. Check for any issues. Then launch fully and see all data together in one place.',
    disabledSoftLaunchMessage = 'You cannot soft launch your project when incremental fieldwork is enabled.',
    isPanelCint
  }: Props = props
  const { classes } = useStyles()

  const handleToggle: (event: React.ChangeEvent<HTMLInputElement>) => void = (
    event
  ) => {
    onToggle(event)
  }

  const handleInputBlur: (value: string) => void = (value) => {
    if (onInputBlur) onInputBlur(value)
  }

  const inputErrorClassName = hasInputError ? classes.inputErrorClassName : ''

  return (
    <>
      {isPanelCint && (
        <>
          <Grid container item direction="row" justifyContent="space-between">
            <Grid className={classes.infoIcon}>
              <Tooltip
                title={
                  isConfiguredForIncrementalFieldwork
                    ? disabledSoftLaunchMessage
                    : ''
                }
                tipPlacement={TipPlacement.Top}
                disabled={!isConfiguredForIncrementalFieldwork}
              >
                <Switch
                  checked={isToggledOn}
                  onChange={handleToggle}
                  first
                  last
                  label="Soft launch"
                  disabled={isConfiguredForIncrementalFieldwork}
                />
              </Tooltip>
              <InfoIcon text={infoText} paddingLeft={false} />
            </Grid>
          </Grid>
          {isToggledOn ? (
            <Grid container item direction="column">
              <Grid item>
                <Text
                  size={TextSize.m}
                  weight={TextWeight.SemiBold}
                  className={classes.labelSamplesNeeded}
                >
                  Soft launch (completes)
                </Text>
              </Grid>
              <Grid container item>
                <Grid item className={inputErrorClassName}>
                  <Input
                    variant={InputVariant.Filled}
                    placeholder={String(inputPlaceholder || 100)}
                    inputWeight={InputWeight.Regular}
                    inputFontSize={InputFontSize.m}
                    onBlur={(event) => {
                      handleInputBlur(event.target.value)
                    }}
                    inputType={InputType.Number}
                    defaultValue={softLaunchSamplesNeeded}
                    className={classes.inputSamplesNeeded}
                  />
                </Grid>
                {hasInputError && (
                  <Grid
                    item
                    xs={1}
                    justifyContent="flex-start"
                    alignContent="left"
                  >
                    <InfoIcon
                      className={classes.errorIcon}
                      iconColor={InfoIconColor.Error}
                      text={inputErrorMessage || ''}
                      tooltipPlacement={TooltipPlacement.Right}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          ) : null}
        </>
      )}
    </>
  )
}

export default ConfirmationSoftLaunchControl
