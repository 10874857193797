import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { GET_COST_QUOTE } from '../../data/gql/questionnaire/queries/getCostQuote'
import { getFeasibilityEstimateRefetchQuery } from '../../data/gql/questionnaire/queries/getFeasibilityEstimate'
import { GET_PRESET_AUDIENCES_V2 } from '../../data/gql/questionnaire/queries/getPresetAudiences'
import { LoggerErrorType } from '../../data/logger'
import { FieldworkAudienceType } from '../../data/model/audience'
import { FieldworkAudienceType as FwAudienceType } from '../../data/model/fieldwork'
import { useFieldworkData } from '../../modules/Audience/Audience.hooks'
import { captureApolloError } from '../../utils/HelperFunctions'
import { convertAudienceType } from '../../utils/audienceHelper'
import useDisplayedLOI from '../localState/useDisplayedLOI'
import { useProjectId } from '../useProjectId'
import { useSurveyId } from '../useSurveyId'
import { useUpdateFieldworkV2 } from './createOrUpdateFieldworkV2'
import useGetDraftAudience from './useGetDraftAudience'

export const CUSTOM_AUDIENCE_PRESET_NAME = 'Custom'

export interface AudiencePreset {
  name: string
  presetAudienceId?: string
  isWeighted?: boolean
}

export const useSetPresetAudience = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const { displayedLOI } = useDisplayedLOI()
  const [loading, setLoading] = useState<boolean>(false)

  const { draftAudience, refetchAudience } = useGetDraftAudience()
  const { updateFieldworkV2 } = useUpdateFieldworkV2()
  const { fieldworkData } = useFieldworkData()

  const setPresetAudience: (preset: AudiencePreset) => Promise<void> = async (
    preset
  ) => {
    setLoading(true)
    let inputIfCustomMembers = {}
    const audience = fieldworkData?.audience[0]

    if (
      draftAudience?.members.some(
        (audience) => audience.memberType !== FieldworkAudienceType.Standard
      )
    ) {
      inputIfCustomMembers = {
        incidenceRate: 100,
        audienceDescription:
          audience?.__typename !== 'CintAudience'
            ? audience?.description
            : undefined
      }
    }

    await updateFieldworkV2({
      variables: {
        input: {
          surveyId,
          panelSupplierCode: convertAudienceType(
            audience?.__typename as FwAudienceType
          ),
          ...inputIfCustomMembers,
          presetAudienceId: preset.presetAudienceId
        }
      },
      refetchQueries: [
        getFeasibilityEstimateRefetchQuery(projectId, surveyId, displayedLOI),
        GET_COST_QUOTE
      ]
    })

    await refetchAudience()
    setLoading(false)
  }

  return { setPresetAudience, loading }
}

export const useGetPresetAudiences = (
  countryCode: string,
  language: string
) => {
  const { data, loading } = useQuery(GET_PRESET_AUDIENCES_V2, {
    context: { clientName: 'questionnaire' },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloQuery,
        'getPresetAudiencesV2',
        error
      )
    },
    variables: {
      locale: {
        country: countryCode,
        language
      }
    },
    skip: !countryCode || !language
  })

  return { data: data?.getPresetAudiencesV2, loading }
}
