// src/utils/calculateTotalQuestions.ts
import { EntryType } from '../../../../data/model/questionnaire'
import { DraftQuestionnaireEntry } from '../../../../data/model/questionnaire'
import { flattenEntries } from '../../../../modules/Questionnaire/Questionnaire.utils'

export const calculateTotalQuestions = (
  draftQuestionnaire: any,
  draftAudience: any
): number => {
  const isQuestionEntry = (entry: DraftQuestionnaireEntry) =>
    entry.entryType === EntryType.QuestionEntryType ||
    entry.entryType === EntryType.MatrixEntryType

  const entryQuestionnaireQuestionsCount = draftQuestionnaire
    ? flattenEntries(draftQuestionnaire.entries).filter(isQuestionEntry).length
    : 0

  const membersAudienceQuestionsCount = draftAudience?.members?.length || 0
  return membersAudienceQuestionsCount + entryQuestionnaireQuestionsCount
}
