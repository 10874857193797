import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  FocaldataPreferredApp,
  LoginLocationState,
  appTargetSchema,
  coworkerHost,
  isFocaldataUser,
  projectSetupHost,
  targetDefaults
} from '../utils/authUtils'

const chosenAppSchema = appTargetSchema.optional().nullable().catch('dashboard')

export function useLoginRedirect() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  // We cannot treat the preferred app cookie and URL parameter as equivalent because
  // the cookie value for coworker indicates project_setup whereas the URL parameter
  // indicates coworker
  const loginRedirect = ({ firstLogin }: LoginLocationState = {}) => {
    const preferredApp = FocaldataPreferredApp.get()
    const chosenApp = chosenAppSchema.parse(searchParams.get('app'))

    if (chosenApp || !preferredApp) {
      const target =
        searchParams.get('target') ?? targetDefaults[chosenApp ?? 'dashboard']

      switch (chosenApp) {
        case 'dashboard':
          FocaldataPreferredApp.set('dashboard')
          navigate(target, { state: { firstLogin } })
          break

        case 'project_setup':
          FocaldataPreferredApp.set('coworker')
          window.location.href =
            projectSetupHost + '/' + target.replace(/^\/+/g, '')
          break

        case 'coworker':
          window.location.href =
            coworkerHost + '/' + target.replace(/^\/+/g, '')
          break

        case 'audience_picker':
          const isInsideIframe = window.self !== window.parent
          if (isInsideIframe) {
            window.parent.postMessage('reload', {
              targetOrigin:
                process.env.NODE_ENV === 'development'
                  ? '*'
                  : window.location.origin
            })
          } else {
            window.location.assign(target)
          }
          break

        default:
          // Only showing the choose app screen to focaldata users until we release the coworker app
          if (isFocaldataUser()) {
            navigate('/choose-app?target=' + target, { state: { firstLogin } })
          } else {
            navigate(target, { state: { firstLogin } })
          }
      }
    } else {
      const target = searchParams.get('target') ?? targetDefaults[preferredApp]

      switch (preferredApp) {
        case 'dashboard':
          navigate(target, { state: { firstLogin } })
          break

        // We've used coworker to mean project setup until now. This will change when we make the new coworker app live
        case 'coworker':
          window.location.href =
            projectSetupHost + '/' + target.replace(/^\/+/g, '')
          break
      }
    }
  }

  return { loginRedirect }
}
