import React, { useCallback } from 'react'
import { draftQuestionnaireRefetchQuery } from '../../../data/gql/questionnaire/queries'
import { fieldworkRefetchQuery } from '../../../data/gql/questionnaire/queries/fieldwork'
import { getFeasibilityEstimateRefetchQuery } from '../../../data/gql/questionnaire/queries/getFeasibilityEstimate'
import { useUpdateFieldworkV2 } from '../../../hooks/audience/createOrUpdateFieldworkV2'
import { useFieldwork } from '../../../hooks/useFieldwork'
import { useProjectId } from '../../../hooks/useProjectId'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { checkIsResultsPage } from '../../../utils/HelperFunctions'
import { convertAudienceType } from '../../../utils/audienceHelper'
import CompletesInputControl from './CompletesInput.control'
import CompletesInputLoading from './CompletesInputLoading'

interface Props {
  inputLabel?: string
  fullWidth?: boolean
}

const CompletesInput: React.FC<Props> = (props: Props) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const { inputLabel } = props

  const { fieldwork, loading: loadingFieldwork } = useFieldwork({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  })

  const defaultCompletes = fieldwork?.samplesNeeded.unadjusted
  const loi = fieldwork?.lengthOfInterview.adjusted || 2

  const { updateFieldworkV2 } = useUpdateFieldworkV2()
  const panelSupplierCode = convertAudienceType(
    fieldwork?.audience[0].__typename
  )

  const handleSaveCompletes = useCallback(
    (completes: number | undefined) => {
      if (
        completes !== undefined &&
        completes !== defaultCompletes &&
        completes > 0
      ) {
        updateFieldworkV2({
          variables: {
            input: {
              surveyId,
              panelSupplierCode: panelSupplierCode,
              lengthOfInterview: null,
              samplesNeeded: completes,
              startDate: null,
              endDate: null,
              countryCode: null
            }
          },
          refetchQueries: [
            fieldworkRefetchQuery(surveyId),
            draftQuestionnaireRefetchQuery(projectId, surveyId),
            getFeasibilityEstimateRefetchQuery(projectId, surveyId, loi)
          ]
        })
      }
    },
    [
      defaultCompletes,
      loi,
      panelSupplierCode,
      projectId,
      surveyId,
      updateFieldworkV2
    ]
  )

  if (loadingFieldwork) {
    return <CompletesInputLoading />
  }

  const isInputDisabled = checkIsResultsPage()

  return (
    <CompletesInputControl
      disabled={isInputDisabled}
      defaultCompletes={defaultCompletes}
      inputLabel={inputLabel}
      saveCompletes={handleSaveCompletes}
    />
  )
}

export default CompletesInput
