import {
  Button,
  ButtonVariant,
  Icon,
  IconButton,
  IconColor,
  IconName,
  IconSize,
  KebabMenu,
  KebabMenuIconPosition,
  KebabMenuOption
} from '@focaldata/cin-ui-components'
import React from 'react'
import {
  EntryType,
  QuestionSettingCode,
  QuestionTypeCode,
  SettingValue
} from '../../../data/model/questionnaire'
import { isFocaldataUser } from '../../../utils/authUtils'
import { useProjectType } from '../../Project/Project.hooks'
import {
  AddQuestionOption,
  AddQuestionSecondaryOption
} from './AddQuestionMenu.model'
import useStyles from './AddQuestionMenu.styles'

interface Props {
  showAddButton?: boolean
  showSectionMenu?: boolean
  onCreateNewQuestion: (addQuestionOption: AddQuestionOption) => void
  onCreateSecondaryEntry: (
    addQuestionSecondaryOption: AddQuestionSecondaryOption
  ) => void
  onImportFromOtherSurveyClick: () => void
}

const AddQuestionControl: React.FC<Props> = ({
  showAddButton = false,
  showSectionMenu = false,
  onCreateNewQuestion,
  onCreateSecondaryEntry,
  onImportFromOtherSurveyClick
}: Props) => {
  const { classes, cx: classNames } = useStyles()
  const { isFdChat } = useProjectType()
  const showMaxDiffQuestion = isFocaldataUser() && !isFdChat
  const allKebabMenuOptions: KebabMenuOption[] = []

  allKebabMenuOptions.push({
    id: allKebabMenuOptions.length,
    textItem: 'Single select',
    onClickItem: () => {
      onCreateNewQuestion({
        questionTypeCode: QuestionTypeCode.Basic,
        settings: [
          {
            questionSettingCode: QuestionSettingCode.BasicChoice,
            settingValue: SettingValue.SingleChoice
          }
        ]
      })
    },
    iconName: IconName.SingleChoice
  })
  allKebabMenuOptions.push({
    id: allKebabMenuOptions.length,
    textItem: 'Multi select',
    onClickItem: () => {
      onCreateNewQuestion({
        questionTypeCode: QuestionTypeCode.Basic,
        settings: [
          {
            questionSettingCode: QuestionSettingCode.BasicChoice,
            settingValue: SettingValue.MultipleChoice
          }
        ]
      })
    },
    iconName: IconName.MultipleChoice
  })
  allKebabMenuOptions.push({
    id: allKebabMenuOptions.length,
    textItem: 'Matrix (grid)',
    onClickItem: () => {
      onCreateNewQuestion({
        questionTypeCode: QuestionTypeCode.Matrix,
        settings: [
          {
            questionSettingCode: QuestionSettingCode.MatrixChoice,
            settingValue: SettingValue.SingleChoice
          }
        ]
      })
    },
    iconName: IconName.Matrix
  })
  allKebabMenuOptions.push({
    id: allKebabMenuOptions.length,
    textItem: 'Ranked',
    onClickItem: () => {
      onCreateNewQuestion({
        questionTypeCode: QuestionTypeCode.Ranked
      })
    },
    iconName: IconName.Ranked
  })
  allKebabMenuOptions.push({
    id: allKebabMenuOptions.length,
    textItem: 'Slider',
    onClickItem: () => {
      onCreateNewQuestion({
        questionTypeCode: QuestionTypeCode.Scale
      })
    },
    iconName: IconName.Scale
  })
  allKebabMenuOptions.push({
    id: allKebabMenuOptions.length,
    textItem: 'Free text',
    onClickItem: () => {
      onCreateNewQuestion({
        questionTypeCode: QuestionTypeCode.FreeText,
        settings: [
          {
            questionSettingCode: QuestionSettingCode.ContentStructure,
            settingValue: SettingValue.Unstructured
          },
          {
            questionSettingCode: QuestionSettingCode.CharacterLimit,
            settingValue: SettingValue.TwoHundred
          },
          {
            questionSettingCode: QuestionSettingCode.CharacterMinimum,
            settingValue: SettingValue.Two
          }
        ]
      })
    },
    iconName: IconName.Freetext
  })
  if (showMaxDiffQuestion) {
    allKebabMenuOptions.push({
      id: allKebabMenuOptions.length,
      textItem: 'MaxDiff',
      onClickItem: () => {
        onCreateNewQuestion({
          questionTypeCode: QuestionTypeCode.MaxDiff
        })
      },
      iconName: IconName.PlayCircleOutline
    })
  }
  allKebabMenuOptions.push({
    id: allKebabMenuOptions.length,
    textItem: 'Text instruction',
    onClickItem: () => {
      onCreateSecondaryEntry({ entryType: EntryType.TextCardEntryType })
    },
    hasDivider: !isFdChat,
    iconName: IconName.TextInstruction
  })

  if (!isFdChat) {
    allKebabMenuOptions.push({
      id: allKebabMenuOptions.length,
      textItem: 'A/B fork',
      onClickItem: () => {
        onCreateSecondaryEntry({ entryType: EntryType.ForkEntryType })
      },
      hasDivider: true,
      iconName: IconName.Fork
    })
  }

  if (showSectionMenu && !isFdChat) {
    allKebabMenuOptions.push({
      id: allKebabMenuOptions.length,
      textItem: 'Section',
      onClickItem: () => {
        onCreateSecondaryEntry({ entryType: EntryType.SectionEntryType })
      },
      hasDivider: true,
      iconName: IconName.Section
    })
  }

  if (!isFdChat) {
    allKebabMenuOptions.push({
      id: allKebabMenuOptions.length,
      textItem: 'Import questions',
      onClickItem: onImportFromOtherSurveyClick,
      iconName: IconName.ImportQuestions,
      disabled: !showSectionMenu
    })
  }

  const getButton = (): JSX.Element => {
    return showAddButton ? (
      <Button
        variant={ButtonVariant.SecondaryFilled}
        startIcon={
          <Icon
            name={IconName.Add}
            iconColor={IconColor.White}
            size={IconSize.Large}
          />
        }
      >
        Add
      </Button>
    ) : (
      <div
        className={classNames(
          'fd-grid',
          'fd-container',
          classes.iconButtonContainer
        )}
      >
        <div className="fd-grid fd-item fd-xs-11" />
        <div className="fd-grid fd-item fd-xs-1">
          <IconButton
            ariaLabel="Add question beneath"
            hasShadow
            iconName={IconName.Add}
            iconSize={IconSize.Medium}
            iconColor={IconColor.Emphasis}
            className={classes.iconButton}
          />
        </div>
      </div>
    )
  }

  return (
    <KebabMenu
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      kebabMenuOptions={allKebabMenuOptions}
      iconColor={IconColor.Background}
      className={classes.iconButton}
      menuButton={getButton()}
      iconPosition={KebabMenuIconPosition.Left}
    />
  )
}

export default AddQuestionControl
