import { UpdateFieldworkV2Input } from '../../../data/gql-gen/questionnaire/graphql'
import { draftAudienceRefetchQuery } from '../../../data/gql/questionnaire/queries/audiences'
import { getFeasibilityEstimateRefetchQuery } from '../../../data/gql/questionnaire/queries/getFeasibilityEstimate'
import { FieldworkAudienceType } from '../../../data/model/fieldwork'
import { useUpdateFieldworkV2 } from '../../../hooks/audience/createOrUpdateFieldworkV2'
import { useFieldwork } from '../../../hooks/useFieldwork'
import { useProjectId } from '../../../hooks/useProjectId'
import { useSurveyId } from '../../../hooks/useSurveyId'
import {
  convertAudienceType,
  panelOptions
} from '../../../utils/audienceHelper'
import SelectLoading from '../SelectLoading'
import PanelPickerControl from './PanelPicker.control'

const PanelPicker = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const { fieldwork, loading } = useFieldwork()

  const { updateFieldworkV2 } = useUpdateFieldworkV2()

  if (loading) {
    return <SelectLoading label="Panel" />
  }

  const initialPanelSupplierCode = convertAudienceType(
    fieldwork?.audience[0]?.__typename as FieldworkAudienceType
  )

  const handleChangePanel = async (event: any): Promise<void> => {
    const newPanel = panelOptions.find(
      (option) => option.value === (event.target.value as string)
    )

    if (newPanel) {
      const updateFieldworkInput: UpdateFieldworkV2Input = {
        surveyId,
        panelSupplierCode: String(newPanel.id),
        countryCode: fieldwork?.locale.country,
        countryLanguage: fieldwork?.locale.language,
        audienceDescription: newPanel.name
      }

      await updateFieldworkV2({
        variables: {
          input: updateFieldworkInput
        },
        refetchQueries: [
          draftAudienceRefetchQuery(projectId, surveyId),
          getFeasibilityEstimateRefetchQuery(
            projectId,
            surveyId,
            fieldwork?.lengthOfInterview.unadjusted ?? 5 // 5 minutes is the min default length of interview used for the initial estimation
          )
        ]
      })
    }
  }

  return (
    <PanelPickerControl
      panelCode={initialPanelSupplierCode}
      panels={panelOptions}
      onChangeSelectPanel={handleChangePanel}
    />
  )
}

export default PanelPicker
