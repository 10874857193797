import { questionnaireGql } from '../../../gql-gen/questionnaire'

export const GET_FIELDWORK_LOCALE = questionnaireGql(/* GraphQL */ `
  query fieldworkLocale($surveyId: ID!) {
    fieldwork: fieldworkV2(
      surveyId: $surveyId
      skipCintQuotaGroupJsonRefresh: true
    ) {
      surveyId
      locale {
        language
        country
      }
    }
  }
`)
