import { ApolloError } from '@apollo/client'
import { datadogLogs } from '@datadog/browser-logs'
import { SelectOption } from '@focaldata/cin-ui-components'
import orderBy from 'lodash/orderBy'
import { matchPath } from 'react-router-dom'
import uuid from 'uuid-random'
import { LogAmplitudeEvent } from '../../amplitude'
import { EventType } from '../../amplitude/eventType'
import {
  Country,
  CustomOptionValidationError
} from '../../data/gql-gen/fieldwork/graphql'
import { LoggerErrorType } from '../../data/logger'
import { CustomCriterionValidationErrors } from '../../data/model/fieldwork'
import {
  DraftQuestionnaireEntry,
  EntryType,
  QuestionSettingCode,
  SettingValue
} from '../../data/model/questionnaire'
import { getQuestionEnabledSettings } from '../questionnaireUtils'

export type CountryOption = Pick<SelectOption, 'id' | 'name'> & {
  countryCode: string
  value: string
}

export const reorderCountriesDropDown = (
  countries: Country[]
): CountryOption[] => {
  return orderBy(
    countries.map((country, index, countries) => {
      const hasMultipleLanguages =
        countries.filter(
          ({ countryCode }) => countryCode === country.countryCode
        ).length > 1
      const name = hasMultipleLanguages
        ? `${country.name} - ${country.locale.language}`
        : country.name
      const option: CountryOption = {
        id: `${country.countryCode}-${index}`,
        name,
        countryCode: country.countryCode,
        value: `${country.countryCode}-${country.locale.language}`
      }
      return option
    }),
    ['name'],
    ['asc']
  )
}

export const suggestedCountryOption = (
  options: SelectOption[] | undefined
): SelectOption[] => {
  return orderBy(
    options?.filter(
      (option) => option.countryCode === 'US' || option.countryCode === 'GB'
    ),
    ['name'],
    ['desc']
  )
}

export const percentToProportion: (percent: number) => number = (percent) =>
  Math.round((percent + Number.EPSILON) * 10) / 1000

export const proportionToPercent: (proportion: number) => number = (
  proportion
) => Math.round((proportion + Number.EPSILON) * 1000) / 10

export const calculateTotalCost = (
  cpi: number,
  numOfwantedCompletes: number
): number => {
  const totalCost =
    Math.round((cpi * numOfwantedCompletes + Number.EPSILON) * 100) / 100

  return totalCost
}

export const formatThousandsWithCommas: (cost: number) => string = (cost) => {
  return cost.toLocaleString()
}

export const getSettingValue = (bool: boolean) => {
  if (bool) {
    return SettingValue.Enabled
  }
  return SettingValue.Disabled
}

export const getSettingBool: (
  entry: DraftQuestionnaireEntry | undefined,
  questionSettingCode: QuestionSettingCode
) => boolean = (entry, questionSettingCode) => {
  if (
    entry &&
    (entry.entryType === EntryType.QuestionEntryType ||
      entry.entryType === EntryType.MatrixEntryType)
  ) {
    const enabledSettings = getQuestionEnabledSettings(
      entry.entryItem.settingValues
    )
    return enabledSettings.has(questionSettingCode)
  }
  return false
}

export const createPreviewUrl = (surveyId: string, panel: string) => {
  const domain = process.env.REACT_APP_RESPONDENT_DOMAIN
  const rid = uuid()
  return `${domain}/info?rid=${rid}&preview=true&sid=${surveyId}&ps=${panel}&type=survey`
}

export const handleShowPreview = (surveyId: string, panel: string) => {
  LogAmplitudeEvent(EventType.PreviewedSurvey, { surveyId })
  const previewUrl = createPreviewUrl(surveyId, panel)
  window.open(previewUrl, '_blank')
}

const checkIfCurrentPathMatches = (pathToCompareWith: string): boolean => {
  const currentPath = window.location.pathname
  const hasMatch = !!matchPath(
    {
      path: pathToCompareWith,
      end: false
    },
    currentPath
  )

  return hasMatch
}

export const checkIsConfirmationPage = (): boolean => {
  return checkIfCurrentPathMatches(
    '/project/:projectId/survey/:surveyId/confirm'
  )
}

export const checkIsResultsPage: () => boolean = () => {
  return checkIfCurrentPathMatches(
    '/project/:projectId/survey/:surveyId/results'
  )
}

export const checkIsProjectsPage = (): boolean => {
  return checkIfCurrentPathMatches('/projects')
}

export const checkIsAudiencePage = (): boolean => {
  return checkIfCurrentPathMatches(
    '/project/:projectId/survey/:surveyId/audience'
  )
}

export const checkIsFDChatResultsPage = (): boolean => {
  return checkIfCurrentPathMatches(
    '/project/:projectId/fdchat/:surveyId/results'
  )
}

export const isMasterSurveyPage = (): boolean => {
  return checkIfCurrentPathMatches('/project/:projectId/master/:surveyId')
}

export const checkIsQuestionnairePage = (): boolean => {
  return checkIfCurrentPathMatches(
    '/project/:projectId/survey/:surveyId/questionnaire'
  )
}

export const checkIfMasterSurveyPage = (): boolean => {
  return checkIfCurrentPathMatches('/project/:projectId/master/:surveyId')
}

export const getMinutesSuffix = (lengthOfInterview: number): string => {
  if (lengthOfInterview === 1) {
    return 'minute'
  }
  return 'minutes'
}

export const getDaysSuffix = (deliveryDays: number): string => {
  if (deliveryDays && deliveryDays <= 1) {
    return 'day'
  }
  return 'days'
}

export const numberToFixedString = (myNumber: number): string => {
  if (Number.isSafeInteger(myNumber)) {
    return myNumber.toFixed(0)
  }

  return myNumber.toFixed(1)
}

export const getPercentageFromFloat: (
  float: number | undefined
) => number | undefined = (float) => {
  if (float) {
    return float * 100
  }
  return undefined
}

export const isQuotaValueZeroError: (
  validationErrors: CustomCriterionValidationErrors | undefined,
  responseOptionLk: string
) => boolean | undefined = (validationErrors, responseOptionLk) => {
  return (
    validationErrors?.customCriterionOptionsErrors
      .find(
        (customCriterionOptionsError) =>
          customCriterionOptionsError.responseOptionLk === responseOptionLk
      ) // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      ?.errors?.includes(CustomOptionValidationError.CustomQuotaIsZeroError)
  )
}

export const getEnvironment: () =>
  | 'development'
  | 'PR'
  | 'staging'
  | 'production' = () => {
  if (
    process.env.NODE_ENV === 'production' &&
    window.location.hostname === 'dashboard.focaldata.com'
  ) {
    return 'production'
  }
  if (
    process.env.NODE_ENV === 'production' &&
    window.location.hostname.includes('focaldata.dev')
  ) {
    return 'staging'
  }
  if (
    process.env.NODE_ENV === 'production' &&
    window.location.hostname.includes('cin-fd-surveys-pr')
  ) {
    return 'PR'
  }
  return 'development'
}

export const getPage: () =>
  | 'projectsPage'
  | 'audiencePage'
  | 'questionnairePage'
  | 'confirmPage'
  | 'resultsPage'
  | 'unknownPage' = () => {
  if (checkIsProjectsPage()) {
    return 'projectsPage'
  }
  if (checkIsAudiencePage()) {
    return 'audiencePage'
  }
  if (checkIsQuestionnairePage()) {
    return 'questionnairePage'
  }
  if (checkIsConfirmationPage()) {
    return 'confirmPage'
  }
  if (checkIsResultsPage()) {
    return 'resultsPage'
  }
  return 'unknownPage'
}

export const captureApolloError: (
  errorType: LoggerErrorType,
  mutationName: string,
  apolloError: ApolloError
) => void = (errorType, mutationName, apolloError) => {
  const page = getPage()
  const errorMessage = `[${mutationName}] ${errorType}_${page} ${apolloError.message}`
  if (getEnvironment() !== 'production') {
    console.error(errorMessage, apolloError)
  }
  datadogLogs.logger.error(errorMessage, apolloError)
}

export const getResultsPath: (surveyId: string) => string = (surveyId) =>
  `/survey/${surveyId}/results`

export const validateSurveyPath = (): boolean => {
  return [
    '/project/:projectId/survey/:surveyId/audience',
    '/project/:projectId/survey/:surveyId/questionnaire',
    '/project/:projectId/survey/:surveyId/confirm',
    '/project/:projectId/survey/:surveyId/results'
  ].some(checkIfCurrentPathMatches)
}

export const MATRIX_QUESTION_ID_DELIMITER = '__'
export const composeSelectedQuestionLk: (
  questionLk: string,
  matrixTitleLk: string | undefined
) => string = (questionLk, matrixTitleLk) =>
  `${questionLk}${MATRIX_QUESTION_ID_DELIMITER}${matrixTitleLk}`

export const getIdsFromSelectedQuestionLk: (
  selectedQuestionLk: string
) => [string, string | undefined] = (selectedQuestionLk) => {
  const [questionLk, matrixTitleLk] = selectedQuestionLk.split(
    MATRIX_QUESTION_ID_DELIMITER
  )

  return [questionLk, matrixTitleLk]
}

/**
 * Allows to safely get values by non-existent keys in a wrapped object
 * @example
 * const o1 = safeObject({}, 42)
 * o1.nonExistentKey // 42
 * const o2 = safeObject(undefined, '')
 * o2.nonExistentKey // ''
 */
export const safeObject = <T>(
  object: Record<string, T> = {},
  defaultValue?: T
): Record<string, T> => {
  return new Proxy(object, {
    get(object, key: string) {
      return key in object ? object[key] : defaultValue
    }
  })
}

export const truncate = (str: string, numbOfCharacters: number) => {
  return str.length > numbOfCharacters
    ? `${str.slice(0, numbOfCharacters - 1)}...`
    : str
}

export const isURL = (url: string) => url.includes('http')

/**
 * Generates a unique id that increments each time this function is called
 *
 */
export const uniqueId = (() => {
  let counter = 0

  return (str = '') => {
    counter += 1
    return `${str}${counter}`
  }
})()

/**
 * Temporary utility to use as feature toggle.
 * Designed to disable multi-market feature in production.
 * @returns true if the multi-market feature is enabled
 */
export const isMultiMarketFeatureEnabled = () => {
  return getEnvironment() !== 'production'
}
