import {
  Grid,
  Skeleton,
  Text,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import React from 'react'

interface LoadingProps {
  label: string
}

const SelectLoading: React.FC<LoadingProps> = ({ label }) => {
  return (
    <>
      <Grid item>
        <Text size={TextSize.m} weight={TextWeight.SemiBold}>
          {label}
        </Text>
      </Grid>
      <Grid item>
        <Skeleton width={345} height={45} />
      </Grid>
    </>
  )
}

export default SelectLoading
