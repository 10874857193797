import { Option } from '@focaldata/cin-ui-components'
import { FieldworkAudienceType } from '../data/model/fieldwork'

export const panelOptions: Option[] = [
  { id: 'cint', name: 'Cint', value: 'cint' },
  { id: 'prolific', name: 'Prolific', value: 'prolific' },
  { id: 'fd', name: 'Custom', value: 'fd' }
]

export const convertAudienceType = (
  value: FieldworkAudienceType | undefined
) => {
  return value === 'CintAudience'
    ? 'cint'
    : value === 'ProlificAudience'
      ? 'prolific'
      : 'fd'
}
