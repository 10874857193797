import { Button, Grid } from '@focaldata/cin-ui-components'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Account } from '../data/model/accountUser'
import { GetAllActiveAccounts } from '../data/rest/accountUser'
import UserSession from '../utils/UserSession'
import { AccessToken, isFocaldataUser } from '../utils/authUtils'

const AccountSwitcher: React.FC = () => {
  const currentSelectedAccountId = UserSession.maybeGetAccountId()
  const [accounts, setAccounts] = useState<{ id: string; name: string }[]>([])
  const [selectedAccount, setSelectedAccount] = useState(
    currentSelectedAccountId
  )
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const navigate = useNavigate()

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const accessToken = AccessToken.get()
        if (accessToken) {
          const response = await GetAllActiveAccounts(accessToken)
          const accountList = response.data.map((account: Account) => ({
            id: account.accountId,
            name: account.name
          }))
          setAccounts(accountList)
        } else {
          navigate('/login')
        }
      } catch (err: any) {
        setErrorMessage(`Failed to fetch accounts: ${err.message}`)
        return new Error(err)
      }
    }
    fetchAccounts()
  }, [navigate])

  const handleAccountChange = (selectedId: string) => {
    if (selectedId === selectedAccount) return
    else setSelectedAccount(selectedId)
  }

  const handleButtonClick = () => {
    if (selectedAccount) {
      const account = accounts.find((acc) => acc.id === selectedAccount)
      if (account) {
        UserSession.setAccountId(account.id)
        UserSession.setAccountName(account.name)
        navigate('/')
      }
    } else {
      alert('Please select an account first.')
    }
  }

  const options = accounts.map((account) => ({
    value: account.id,
    label: `${account.name} - ${account.id}`
  }))

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="flex-start"
      sx={{ height: '100vh', pt: 2 }}
    >
      <Grid item textAlign={'center'}>
        <h1 style={{ marginBottom: '50px' }}>Account Switcher</h1>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        {isFocaldataUser() && options.length > 0 && (
          <Autocomplete
            id="account-select"
            options={options}
            value={options.find((option) => option.value === selectedAccount)}
            renderInput={(params) => <TextField {...params} />}
            placeholder="Type to search..."
            onChange={(event, value) =>
              value && handleAccountChange(value.value)
            }
            sx={{ width: 600, marginBottom: 2 }}
          />
        )}
        <Button onClick={handleButtonClick}>Go to Main Page</Button>
      </Grid>
    </Grid>
  )
}

export default AccountSwitcher
