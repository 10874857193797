import { QueryHookOptions, useQuery } from '@apollo/client'
import { FieldworkLocaleQuery } from '../data/gql-gen/questionnaire/graphql'
import { GET_FIELDWORK_LOCALE } from '../data/gql/questionnaire/queries/locale'
import { LoggerErrorType } from '../data/logger'
import { captureApolloError } from '../utils/HelperFunctions'
import { useSurveyId } from './useSurveyId'

export const useCachedLocale = (options?: QueryHookOptions) => {
  const surveyId = useSurveyId()

  const { data } = useQuery<FieldworkLocaleQuery>(GET_FIELDWORK_LOCALE, {
    context: { clientName: 'questionnaire' },
    variables: {
      surveyId
    },
    onError: (error) => {
      captureApolloError(LoggerErrorType.ApolloQuery, 'locale', error)
    },
    ...options,
    fetchPolicy: 'cache-only'
  })

  if (!data?.fieldwork) {
    throw new Error('Locale not found')
  }

  return data.fieldwork.locale
}
