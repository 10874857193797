import { produce } from 'immer'
import isEmpty from 'lodash/isEmpty'
import { useContext } from 'react'
import { LogAmplitudeEvent } from '../../amplitude'
import { EventType } from '../../amplitude/eventType'
import {
  DraftCustomAudienceItem,
  DraftFieldworkAudienceMember,
  FieldworkV2Query
} from '../../data/gql-gen/questionnaire/graphql'
import { GET_AUDIENCES } from '../../data/gql/questionnaire/queries/audiences'
import { GET_CUSTOM_CRITERIA } from '../../data/gql/questionnaire/queries/customCriteria'
import { MemberSettingCode, SettingValue } from '../../data/model/audience'
import { useCreateAudienceMemberV2 } from '../../hooks/audience/createAudienceMemberV2'
import { useAudienceCountries } from '../../hooks/audience/useAudienceCountries'
import useGetDraftAudience from '../../hooks/audience/useGetDraftAudience'
import useStandardAudienceListGqlOperations from '../../hooks/audience/useStandardAudienceListGqlOperations'
import { useFieldwork } from '../../hooks/useFieldwork'
import { useCachedLocale } from '../../hooks/useLocale'
import { useProjectId } from '../../hooks/useProjectId'
import { useSurveyId } from '../../hooks/useSurveyId'
import AudienceContext, {
  setAudienceMembers,
  setNewAudienceQuestionLk
} from './Audience.context'
import {
  createEmptyCustomAudience,
  createEmptyCustomCriterion
} from './CustomAudienceList/CustomAudienceList.utils'

const B2B_AUDIENCE_CATEGORIES = new Set(['Business & Occupation'])
const DEFAULT_CATEGORY_NAMES = new Set(['Demographic', 'Geographic'])
const HIGH_QUALITY_MARKET_COUNTRY_CODES = new Set([
  'US', // USA
  'CA', // Canada
  'GB', // UK
  'DE', // Germany
  'FR', // France
  'IT', // Italy
  'ES', // Spain
  'PT', // Portugal
  'NL', // Netherlands
  'BE', // Belgium
  'CH', // Switzerland
  'AU', // Australia
  'SE', // Sweden
  'FI', // Finland
  'IL', // Israel
  'PL', // Poland
  'ZA', // South Africa
  'BR', // Brazil
  'KR', // South Korea
  'SG' // Singapore
])

export const useFieldworkData = (): {
  fieldworkData?: FieldworkV2Query['fieldwork']
  loading: boolean
} => {
  const { fieldwork, loading } = useFieldwork()

  return { fieldworkData: fieldwork, loading }
}

export const useCountryName = () => {
  const { fieldworkData } = useFieldworkData()

  const { data } = useAudienceCountries()

  const country = data?.find(
    ({ countryCode }) => countryCode === fieldworkData?.locale.country
  )

  return country?.name ?? 'UK'
}

export const useAddCustomAudience = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const { dispatch } = useContext(AudienceContext)
  const { draftAudience } = useGetDraftAudience()

  const createAudienceMemberV2 = useCreateAudienceMemberV2()

  const addCustomAudience: (isMulti: boolean) => void = (isMulti) => {
    const audienceQuestionsNr = draftAudience?.members.length
    if (audienceQuestionsNr !== undefined) {
      if (draftAudience) {
        dispatch(
          setAudienceMembers([
            ...draftAudience.members,
            createEmptyCustomAudience(isMulti)
          ])
        )
        dispatch(setNewAudienceQuestionLk('questionLk'))
      }

      createAudienceMemberV2({
        variables: {
          input: {
            projectId,
            surveyId,
            position: audienceQuestionsNr,
            createCustomMember: {
              settings: [
                {
                  code: MemberSettingCode.CustomAudienceChoice,
                  value: isMulti
                    ? SettingValue.MultipleChoice
                    : SettingValue.SingleChoice
                }
              ],
              defaultOptionsCount: 2,
              areOptionsQualifying: true
            }
          },
          skipIRSNotificationRecording: true
        },
        // optimisticResponse: {
        //   createAudienceMemberV2: {
        //     ...createEmptyCustomAudience(isMulti)
        //   }
        // },
        update: (cache, { data: newAudienceData }) => {
          const newAudience = newAudienceData?.createAudienceMemberV2
          cache.updateQuery(
            {
              query: GET_CUSTOM_CRITERIA,
              variables: {
                projectId,
                surveyId
              }
            },
            (customAudienceCriteriaData) => {
              if (!customAudienceCriteriaData) {
                return undefined
              }
              return produce(customAudienceCriteriaData, (draft: any) => {
                draft.customCriteria.push(createEmptyCustomCriterion())
              })
            }
          )

          if (isEmpty(newAudience)) {
            return
          }

          cache.updateQuery(
            {
              query: GET_AUDIENCES,
              variables: {
                projectId,
                surveyId
              }
            },
            (audienceData) => {
              return produce(audienceData, (draft: any) => {
                draft?.draftAudience.members.push(newAudience)
              })
            }
          )
        },
        onCompleted: ({ createAudienceMemberV2 }) => {
          const { questionLk } = (
            createAudienceMemberV2 as DraftFieldworkAudienceMember
          ).memberItem as DraftCustomAudienceItem
          dispatch(setNewAudienceQuestionLk(questionLk))
        }
      })

      LogAmplitudeEvent(EventType.AddedScreeningQuestion, { surveyId })
    }
  }

  return { addCustomAudience }
}

const shouldDisplayCategory = (
  categoryName: string,
  countryCode: string
): boolean => {
  const isB2B = B2B_AUDIENCE_CATEGORIES.has(categoryName)
  const isLowQualityMarket = !HIGH_QUALITY_MARKET_COUNTRY_CODES.has(countryCode)
  const isB2BCategoryInLowQualityMarket = isB2B && isLowQualityMarket

  return !isB2BCategoryInLowQualityMarket
}

export const useStandardAudienceCategories = (
  showAdvancedCriteria: boolean
) => {
  const locale = useCachedLocale()
  const { audienceCategories } = useStandardAudienceListGqlOperations()

  // `.sort` mutates the array it operates on, so let's copy it first to avoid mutation
  const sortedCategories = [...(audienceCategories ?? [])].sort(
    (categoryA, categoryB) => categoryA.position - categoryB.position
  )

  if (showAdvancedCriteria) {
    return sortedCategories.filter((category) =>
      shouldDisplayCategory(category.category.name, locale.country)
    )
  }

  return sortedCategories.filter((category) =>
    DEFAULT_CATEGORY_NAMES.has(category.category.name)
  )
}
