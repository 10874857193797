import React, { memo, useEffect, useMemo, useState } from 'react'
import {
  DraftCustomAudience,
  DraftStandardAudience,
  FieldworkAudienceType
} from '../../../../data/model/audience'
import { useUpdateAudienceMemberV2 } from '../../../../hooks/audience/updateAudienceMemberV2'
import useGetDraftAudience from '../../../../hooks/audience/useGetDraftAudience'
import SidebarAudienceItemListControl from './SidebarAudienceItemList.control'
import { SidebarAudienceEntry } from './SidebarAudienceItemList.query'

const SidebarAudienceItemList: React.FC = () => {
  const { updateAudienceMemberV2Position } = useUpdateAudienceMemberV2()
  const { draftAudience } = useGetDraftAudience()

  const audienceItems: SidebarAudienceEntry[] = useMemo(
    () =>
      draftAudience?.members
        .filter((m) => {
          if (m.memberType === FieldworkAudienceType.Standard) {
            const standardItem =
              m.memberItem as unknown as DraftStandardAudience
            const hideSurveyQuestionSetting = standardItem.settingValues.find(
              (setting) =>
                setting.code === 'hide_survey_question' &&
                setting.value === 'enabled'
            )
            return !hideSurveyQuestionSetting
          }
          return true
        })
        .map((m): SidebarAudienceEntry => {
          if (m.memberType === FieldworkAudienceType.Standard) {
            const standardItem =
              m.memberItem as unknown as DraftStandardAudience
            return {
              id: m.id,
              contextPosition: m.contextPosition,
              number: m.number,
              position: m.position,
              entryItem: {
                question: {
                  questionId: standardItem.criterion.question.questionId,
                  text: standardItem.criterion.question.text
                },
                settingValues: standardItem.settingValues
              }
            }
          } else {
            const customAudience =
              m.memberItem as unknown as DraftCustomAudience
            return {
              id: m.id,
              contextPosition: m.contextPosition,
              number: m.number,
              position: m.position,
              entryItem: {
                question: {
                  questionId: customAudience.question.questionId,
                  text: customAudience.question.text
                },
                settingValues: customAudience.settingValues
              }
            }
          }
        }) || [],
    [draftAudience]
  )

  const [audienceEntries, setAudienceEntries] =
    useState<SidebarAudienceEntry[]>(audienceItems)

  useEffect(() => {
    setAudienceEntries(audienceItems)
  }, [audienceItems])

  const handleReorderedAudience: (
    destinationIndex: number | undefined,
    sourceIndex: number
  ) => void = (destinationIndex, sourceIndex) => {
    const shouldReorder =
      destinationIndex !== sourceIndex && destinationIndex !== undefined

    if (shouldReorder && sourceIndex < audienceEntries.length) {
      const questionId =
        audienceItems[sourceIndex].entryItem.question.questionId
      if (questionId) {
        updateAudienceMemberV2Position(questionId, destinationIndex)
      }
    }
  }

  return (
    <SidebarAudienceItemListControl
      audienceItems={audienceEntries}
      onReorderAudience={handleReorderedAudience}
    />
  )
}

export default memo(SidebarAudienceItemList)
