import {
  Grid,
  Option,
  Select,
  SelectVariant,
  Text,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'

import React from 'react'

interface Props {
  panelCode?: string
  panels: Option[]
  onChangeSelectPanel: (event: any) => void
}

const PanelPicker: React.FC<Props> = (props: Props) => {
  const { panelCode, panels, onChangeSelectPanel }: Props = props

  return (
    <>
      <Grid item>
        <Text size={TextSize.m} weight={TextWeight.SemiBold}>
          Panel
        </Text>
      </Grid>
      <Grid item>
        <Select
          ariaLabel="Panel select"
          variant={SelectVariant.Filled}
          maxMenuHeight={300}
          optionValue={panelCode}
          options={panels}
          onChange={onChangeSelectPanel}
          fullWidth
        />
      </Grid>
    </>
  )
}

export default PanelPicker
