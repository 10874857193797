import {
  CustomCriterionValidationError,
  CustomOptionValidationError
} from '../gql-gen/fieldwork/graphql'

export interface Locale {
  language: string
  country: string
}

export type FieldworkAudienceType =
  | 'CintAudience'
  | 'ProlificAudience'
  | 'CustomAudience'

export enum EntryType {
  QuestionEntryType = 'QuestionEntryType',
  TextCardEntryType = 'TextCardEntryType',
  MatrixEntryType = 'MatrixEntryType'
}

export interface CustomCriterionValidationErrors {
  questionLk: string
  customCriterionErrors: CustomCriterionValidationError[]
  customCriterionOptionsErrors: CustomCriterionOptionValidationErrors[]
}

export interface StandardCriterionValidationErrors {
  code: string
  standardCriterionErrors: StandardCriterionValidationError[]
  standardCriterionOptionsErrors: StandardCriterionOptionValidationErrors[]
}

export type CriterionValidationErrors =
  | CustomCriterionValidationErrors
  | StandardCriterionValidationErrors

export interface CustomCriterionOptionValidationErrors {
  responseOptionLk: string
  errors: CustomOptionValidationError[]
}

export enum StandardCriterionValidationError {
  InvalidQuotaSumError = 'InvalidQuotaSumError',
  MissingQualificationError = 'MissingQualificationError',
  MissingQualifyingSettingWarning = 'MissingQualifyingSettingWarning',
  MissingQuotasSettingWarning = 'MissingQuotasSettingWarning'
}

export interface StandardCriterionOptionValidationErrors {
  code: string
  // erros may have dynamic strings, e.g. `MinAllowedAgeError_N` where N is a number
  errors: (StandardOptionValidationError | string)[]
}

export enum StandardOptionValidationError {
  InvalidQualificationError = 'InvalidQualificationError',
  QuotaIsZeroError = 'QuotaIsZeroError',
  MinAgeBiggerThanMaxError = 'MinAgeBiggerThanMaxError',
  InvalidCustomizedCriterionOptionCodeError = 'InvalidCustomizedCriterionOptionCodeError',
  AgeRangeOverlapError = 'AgeRangeOverlapError',
  AgeBandsGapError = 'AgeBandsGapError'
}
