import {
  DateInput,
  Grid,
  InfoIcon,
  Select,
  SelectVariant,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import FormControlLabel from '@mui/material/FormControlLabel'
import React from 'react'
import { useStyles } from '.'
import { Increments } from '../../../data/gql-gen/questionnaire/graphql'

interface Props {
  minimumEndDate?: Date
  endDate: Date
  onIncrementalFieldworkSelection: (
    incrementalFieldwork: Increments | null
  ) => void
  onEndDateChange?: (endDate: Date) => void
  infoText?: string
  incrementalFieldwork?: Increments | null
  isPanelCint: boolean
}

const selectOptions = [
  {
    id: 'no_increments',
    value: 'no_increments',
    name: 'No increments'
  },
  {
    id: 'Daily',
    value: 'Daily',
    name: 'Daily'
  },
  {
    id: 'Weekly',
    value: 'Weekly',
    name: 'Weekly'
  },
  {
    id: 'Monthly',
    value: 'Monthly',
    name: 'Monthly'
  }
]

const IncrementalFieldworkControl: React.FC<Props> = (props: Props) => {
  const {
    onIncrementalFieldworkSelection,
    minimumEndDate,
    endDate,
    infoText = 'Launch your project initially with a fraction of the sample. Check for any issues. Then launch fully and see all data together in one place.',
    incrementalFieldwork,
    onEndDateChange,
    isPanelCint
  }: Props = props
  const { classes } = useStyles()

  const handleIncrementalFieldworkSelection: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void = (event) => {
    if (event.target.value === 'no_increments') {
      onIncrementalFieldworkSelection(null)
    } else {
      onIncrementalFieldworkSelection(event.target.value as Increments)
    }
  }
  const selectedValue = incrementalFieldwork ?? 'no_increments'

  return (
    <Grid
      container
      item
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      {/* Row container for Select and DateInput */}
      <Grid container item direction="row" alignItems="center" spacing={2}>
        {/* Select with label */}
        {isPanelCint && (
          <>
            <Grid item>
              <FormControlLabel
                labelPlacement="start"
                label="Fieldwork collection"
                classes={{
                  label: classes.label,
                  labelPlacementStart: classes.labelPlacementStart
                }}
                control={
                  <Select
                    variant={SelectVariant.Filled}
                    selectedTextWeight={TextWeight.Regular}
                    width={125}
                    textSize={TextSize.s}
                    onChange={handleIncrementalFieldworkSelection}
                    options={selectOptions}
                    optionValue={selectedValue}
                    disabled={false}
                  />
                }
              />
            </Grid>

            {/* Info Icon for Select */}
            <Grid item>
              <InfoIcon text={infoText} paddingLeft={false} />
            </Grid>
          </>
        )}
        {/* Date input with label in FormControlLabel */}
        <Grid item>
          {selectedValue !== 'no_increments' && (
            <FormControlLabel
              labelPlacement="start"
              className={classes.label}
              label="End Date"
              classes={{
                label: classes.label,
                labelPlacementStart: classes.labelPlacementStart
              }}
              control={
                <DateInput
                  initialDate={endDate}
                  minDate={minimumEndDate}
                  onDateChange={onEndDateChange}
                />
              }
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default IncrementalFieldworkControl
