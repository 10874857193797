import { ScrollTo } from '@focaldata/cin-ui-components'
import { memo, useCallback, useContext, useEffect, useMemo } from 'react'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import { CustomCriterionValidationError } from '../../../data/gql-gen/fieldwork/graphql'
import {
  DraftCustomAudience,
  FieldworkAudienceType,
  MemberSettingCode,
  SettingValue
} from '../../../data/model/audience'
import { useCreateCustomCriterionOptionV2 } from '../../../hooks/audience/createCustomCriterionOptionV2'
import { useUpdateFieldworkV2 } from '../../../hooks/audience/createOrUpdateFieldworkV2'
import { useRemoveAudienceMemberV2 } from '../../../hooks/audience/removeAudienceMemberV2'
import { useUpdateAudienceMemberV2 } from '../../../hooks/audience/updateAudienceMemberV2'
import useAudienceValidation from '../../../hooks/audience/useAudienceValidation'
import useGetDraftAudience from '../../../hooks/audience/useGetDraftAudience'
import { useFieldwork } from '../../../hooks/useFieldwork'
import { useGetFeasibilityEstimateData } from '../../../hooks/useGetFeasibilityEstimateData'
import useGetLoi from '../../../hooks/useGetLoi'
import { useProjectId } from '../../../hooks/useProjectId'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { convertAudienceType } from '../../../utils/audienceHelper'
import {
  ResponseOrderingOptions,
  getOrdering
} from '../../../utils/questionnaireUtils'
import { useProjectType } from '../../Project/Project.hooks'
import AudienceContext, {
  setAudienceMembers,
  setNewAudienceQuestionLk
} from '../Audience.context'
import { addOrUpdateSettingValue } from '../CustomAudienceList/CustomAudienceList.utils'
import { AUDIENCE_SCROLL_CONTAINER_ID, SCROLL_ID_PREFIX } from '../constants'
import {
  getCustomAudienceValidationErrors,
  getIsEnabledInSettings
} from '../utils'
import AudienceCustomQuestionCardControl from './CustomAudienceQuestionCard.control'

const CustomAudienceQuestionCardContainer = ({
  questionLk
}: {
  questionLk: string
}) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const { isFdChat } = useProjectType()
  const loi = useGetLoi()
  const { dispatch, audienceState } = useContext(AudienceContext)
  const { refetchAudience } = useGetDraftAudience()
  const { refetchFeasibilityEstimateData } = useGetFeasibilityEstimateData()
  const audience = audienceState.audienceMembers.find(
    (member) =>
      member.memberType === FieldworkAudienceType.Custom &&
      (member.memberItem as DraftCustomAudience).questionLk === questionLk
  )
  const { fieldwork } = useFieldwork()
  const panelSupplierCode = convertAudienceType(
    fieldwork?.audience[0]?.__typename
  )

  const customAudience = audience?.memberItem as DraftCustomAudience
  const settingValues = customAudience.settingValues.map((s) => ({
    code: s.code,
    value: s.value
  }))

  const questionMutationVariable = useMemo(
    () => ({
      projectId,
      surveyId,
      memberId: customAudience.questionLk
    }),
    [customAudience.questionLk, projectId, surveyId]
  )
  const { updateAudienceMemberV2, updateAudienceMemberV2Settings } =
    useUpdateAudienceMemberV2()
  const removeAudienceMemberV2 = useRemoveAudienceMemberV2()
  const createCustomCriterionOption = useCreateCustomCriterionOptionV2()
  const { updateFieldworkV2 } = useUpdateFieldworkV2()

  useEffect(() => {
    if (questionLk === audienceState.newAudienceQuestionLk) {
      ScrollTo(
        `${SCROLL_ID_PREFIX}${
          (audience?.memberItem as DraftCustomAudience).questionLk
        }`,
        AUDIENCE_SCROLL_CONTAINER_ID
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const validationErrors = getCustomAudienceValidationErrors(
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    audienceState?.validationErrors,
    customAudience.questionLk
  )

  const { isAudienceInvalid } = useAudienceValidation({
    validationErrors: validationErrors ? [validationErrors] : undefined
  })

  const noQualificationsError =
    isAudienceInvalid &&
    validationErrors?.customCriterionErrors.some(
      (err) =>
        err === CustomCriterionValidationError.MissingCustomQualificationError
    )

  const handleDeleteCustomAudienceQuestion = useCallback(() => {
    dispatch(
      setAudienceMembers(
        audienceState.audienceMembers.filter(
          (member) => member.number !== audience?.number
        )
      )
    )
    removeAudienceMemberV2(questionLk)

    if (
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !audienceState?.audienceMembers
        .filter((a) => a.number !== audience?.number)
        .some((a) => a.memberType !== FieldworkAudienceType.Standard)
    ) {
      updateFieldworkV2({
        variables: {
          input: {
            surveyId,
            incidenceRate: 100,
            panelSupplierCode: panelSupplierCode
          }
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleUpdateTitle = useCallback((newTitle: string) => {
    updateAudienceMemberV2({
      variables: {
        input: {
          ...questionMutationVariable,
          updateCustomMember: {
            text: newTitle
          }
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAddOption = useCallback(() => {
    createCustomCriterionOption({
      variables: {
        input: {
          ...questionMutationVariable,
          projectId,
          surveyId,
          questionId: questionLk,
          position: customAudience.criterionOptions.length,
          isOptionQualifying: true
        }
      }
    })

    dispatch(setNewAudienceQuestionLk(customAudience.questionLk))
  }, [
    createCustomCriterionOption,
    customAudience.criterionOptions.length,
    customAudience.questionLk,
    dispatch,
    projectId,
    questionLk,
    questionMutationVariable,
    surveyId
  ])

  const handleToggleSettingValue = useCallback(() => {
    const newSetting = {
      code: MemberSettingCode.Quotas,
      value: getIsEnabledInSettings(
        customAudience.settingValues,
        MemberSettingCode.Quotas
      )
        ? SettingValue.Disabled
        : SettingValue.Enabled
    }
    const newSettingValues = addOrUpdateSettingValue(settingValues, newSetting)

    updateAudienceMemberV2Settings(questionLk, newSettingValues)
  }, [
    customAudience.settingValues,
    questionLk,
    settingValues,
    updateAudienceMemberV2Settings
  ])

  const handleSumQuotas = useCallback(async () => {
    dispatch(setNewAudienceQuestionLk(customAudience.questionLk))
    const quotaSum = customAudience.criterionOptions
      .filter((co) => co.qualification)
      .reduce<number>(
        (sum, criterionOption) =>
          sum +
          // @todo Legacy eslint violation – fix this when editing
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          (criterionOption.quota ? criterionOption.quota.percent : 0),
        0
      )

    const toDistribute = 1 - quotaSum
    // get all options that are qualifying
    const qualifyingOptions = customAudience.criterionOptions.filter(
      (criterionOption) => criterionOption.qualification
    )
    const valueToAdd = toDistribute / qualifyingOptions.length

    const customQuotaInput = qualifyingOptions.map((qualifyingOption) => ({
      responseOptionId: qualifyingOption.responseOptionLk,
      quota:
        toDistribute < 0
          ? (qualifyingOption.quota?.percent ?? 0) / quotaSum
          : (qualifyingOption.quota?.percent ?? 0) + valueToAdd
    }))

    updateAudienceMemberV2({
      variables: {
        input: {
          ...questionMutationVariable,
          updateCustomMember: {
            customQuotas: customQuotaInput
          }
        }
      }
    }).then(() => {
      refetchAudience({ surveyId })
      refetchFeasibilityEstimateData({
        surveyId,
        lengthOfInterview: loi
      })
    })
  }, [
    dispatch,
    customAudience.questionLk,
    customAudience.criterionOptions,
    updateAudienceMemberV2,
    questionMutationVariable,
    refetchAudience,
    surveyId,
    refetchFeasibilityEstimateData,
    loi
  ])

  const handleSwitchQuestionType = useCallback(
    (isSingleChoice: boolean) => {
      const newSettingValues = settingValues.map((setting) => {
        if (setting.code === MemberSettingCode.CustomAudienceChoice) {
          return {
            ...setting,
            value: isSingleChoice
              ? SettingValue.SingleChoice
              : SettingValue.MultipleChoice
          }
        } else if (
          setting.code === MemberSettingCode.Quotas &&
          !isSingleChoice &&
          getIsEnabledInSettings(
            customAudience.settingValues,
            MemberSettingCode.Quotas
          )
        ) {
          return {
            ...setting,
            value: SettingValue.Disabled
          }
        }
        return setting
      })

      updateAudienceMemberV2Settings(questionLk, newSettingValues)
    },
    [
      settingValues,
      updateAudienceMemberV2Settings,
      questionLk,
      customAudience.settingValues
    ]
  )

  const handleLeavingCard = useCallback(() => {
    if (audienceState.newAudienceQuestionLk !== customAudience.questionLk) {
      dispatch(setNewAudienceQuestionLk(undefined))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audienceState.newAudienceQuestionLk, customAudience.questionLk])

  const handleOnOrderingChange = useCallback(
    async (ordering: ResponseOrderingOptions) => {
      const newSetting = {
        code: MemberSettingCode.RandomiseOptions,
        value:
          ordering === ResponseOrderingOptions.Random
            ? SettingValue.Enabled
            : SettingValue.Disabled
      }

      const newSettingValues = addOrUpdateSettingValue(
        settingValues,
        newSetting
      )

      updateAudienceMemberV2Settings(questionLk, newSettingValues)
    },
    [questionLk, settingValues, updateAudienceMemberV2Settings]
  )

  const isRandomiseOn = getIsEnabledInSettings(
    customAudience.settingValues,
    MemberSettingCode.RandomiseOptions
  )

  const handleScreeningQuestionLimitChanged = useCallback(
    (limit: number) => {
      const newSetting = {
        code: MemberSettingCode.ScreeningQuestionResponseLimit,
        value: limit.toString()
      }
      const newSettingValues = addOrUpdateSettingValue(
        settingValues,
        newSetting
      )
      updateAudienceMemberV2Settings(questionLk, newSettingValues)

      LogAmplitudeEvent(EventType.SetScreeningQuestionResponseOptionsLimit, {
        surveyId,
        questionLk,
        settingValue: limit
      })
    },
    [settingValues, questionLk, surveyId, updateAudienceMemberV2Settings]
  )

  const handleToggleOffScreeningQuestionResponseLimit = useCallback(() => {
    const newSettingValues = settingValues
      .filter(
        (setting) =>
          setting.code !== MemberSettingCode.ScreeningQuestionResponseLimit
      )
      .map((setting) => ({
        code: setting.code,
        value: setting.value
      }))
    updateAudienceMemberV2Settings(questionLk, newSettingValues)

    LogAmplitudeEvent(EventType.RemovedScreeningQuestionResponseOptionsLimit, {
      surveyId,
      questionLk
    })
  }, [settingValues, questionLk, surveyId, updateAudienceMemberV2Settings])

  const isHardDisqualification = getIsEnabledInSettings(
    (audience?.memberItem as DraftCustomAudience).settingValues,
    MemberSettingCode.HardDisqualification
  )

  const onIsHardDisqualificationChange = useCallback(
    (nextIsHardDisqualification: boolean) => {
      const newSetting = {
        code: MemberSettingCode.HardDisqualification,
        value: nextIsHardDisqualification
          ? SettingValue.Enabled
          : SettingValue.Disabled
      }
      const newSettingValues = addOrUpdateSettingValue(
        settingValues,
        newSetting
      )
      updateAudienceMemberV2Settings(questionLk, newSettingValues)

      LogAmplitudeEvent(EventType.SetScreeningQuestionResponseOptionsLimit, {
        surveyId,
        questionLk,
        newSetting
      })
    },
    [settingValues, questionLk, surveyId, updateAudienceMemberV2Settings]
  )

  return (
    <AudienceCustomQuestionCardControl
      audience={audience}
      ordering={getOrdering(isRandomiseOn, false)}
      isAudienceInvalid={isAudienceInvalid}
      noQualificationsError={noQualificationsError}
      shouldTitleInputFocus={questionLk === audienceState.newAudienceQuestionLk}
      onUpdateTitle={handleUpdateTitle}
      onToggleQuotas={handleToggleSettingValue}
      onDelete={handleDeleteCustomAudienceQuestion}
      onAddOption={handleAddOption}
      onLeavingCard={handleLeavingCard}
      onSumQuotas={handleSumQuotas}
      onSwitchQuestionType={handleSwitchQuestionType}
      onOrderingChange={handleOnOrderingChange}
      onSetScreeningQuestionResponseLimit={handleScreeningQuestionLimitChanged}
      onToggleOffScreeningQuestionResponseLimit={
        handleToggleOffScreeningQuestionResponseLimit
      }
      isHardDisqualification={isHardDisqualification}
      onIsHardDisqualificationChange={onIsHardDisqualificationChange}
      isFdchatProject={isFdChat}
    />
  )
}

export default memo(CustomAudienceQuestionCardContainer)
